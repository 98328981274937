import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const Seo = ({ title, description }) => {
  return (
    <Helmet>
      <title>Cabuya Handmade{title && ` | ${title}`}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  )
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

Seo.defaultProps = {
  title: null,
  description: null,
}

export default Seo
