import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import LinkedProduct from '../LinkedProduct/LinkedProduct'
import getShopifyProductData from '../../utils/getShopifyProductData'

import { textSliceContent, highlightContent } from './Slices.module.scss'

const SLICE_TYPES = {
  PRODUCTS: 'products',
  IMAGES: 'images',
  VIDEO: 'video',
  TEXT: 'text',
}

const ALIGNMENTS = {
  AUTO: 'Auto',
  LEFT: 'Izquierda',
  CENTER: 'Centro',
  RIGHT: 'Derecha',
}

const SIZES = {
  AUTO: 'Auto',
  SMALL: 'Pequeño',
  MEDIUM: 'Mediano',
  LARGE: 'Grande',
}

const GRID_ITEM_CLASSNAMES = {
  SMALL: 'md:w-1/2 lg:w-1/4 md:px-3 xl:px-4',
  MEDIUM: 'md:w-1/2 lg:w-1/3 md:px-3 xl:px-4',
  LARGE: 'md:w-1/2 md:px-3 xl:px-4',
}

const getGridClassName = alignment => {
  const base = 'md:flex md:mb-32 2xl:mb-48 md:-mx-3 xl:-mx-4'
  switch (alignment) {
    case ALIGNMENTS.LEFT:
      return base
    case ALIGNMENTS.RIGHT:
      return `${base} justify-end`
    default:
      return `${base} justify-center`
  }
}

const getClassNameByLength = length => {
  switch (length) {
    case 1:
      return GRID_ITEM_CLASSNAMES.LARGE
    case 2:
      return GRID_ITEM_CLASSNAMES.MEDIUM
    default:
      return GRID_ITEM_CLASSNAMES.SMALL
  }
}

const getClassNameBySize = size => {
  switch (size) {
    case SIZES.SMALL:
      return GRID_ITEM_CLASSNAMES.SMALL
    case SIZES.MEDIUM:
      return GRID_ITEM_CLASSNAMES.MEDIUM
    default:
      return GRID_ITEM_CLASSNAMES.LARGE
  }
}

const getItemClassName = (size, length) => {
  switch (size) {
    case SIZES.AUTO:
      return getClassNameByLength(length)
    default:
      return getClassNameBySize(size)
  }
}

const ProductSlice = ({ items, size, allShopifyProduct, addVariantToCart }) => (
  <>
    {items
      .filter(({ product: { document } }) => !!document)
      .map(
        ({
          product: {
            document: {
              id,
              data: {
                title: { text: title },
                sku,
                main_gallery: mainGallery,
                presellDate,
              },
              fields: { pagePath } = {},
              publishedMonthsAgo,
            },
          },
        }) => {
          const shopifyData = getShopifyProductData(
            sku,
            allShopifyProduct.edges
          )
          const [
            {
              image: { localFile, fixed: { src: previewHeroImage } = {} },
            } = {},
          ] = mainGallery
          const heroImage = localFile ? getImage(localFile) : null
          const {
            price,
            productShopifyId,
            variantShopifyId,
            availableForSale,
          } = shopifyData
          const isNew = parseInt(publishedMonthsAgo) < 4
          const isPreorder = !!presellDate && new Date(presellDate) > new Date()
          return (
            <div key={id} className={getItemClassName(size, items.length)}>
              <LinkedProduct
                addVariantToCart={addVariantToCart}
                availableForSale={availableForSale}
                heroImage={heroImage}
                isNew={isNew}
                isPreorder={isPreorder}
                pagePath={pagePath}
                previewHeroImage={previewHeroImage}
                price={price?.amount}
                productShopifyId={productShopifyId}
                title={title}
                variantShopifyId={variantShopifyId}
              />
            </div>
          )
        }
      )}
  </>
)

const ImageSlice = ({ items }) => (
  <>
    {items.map(
      (
        {
          size,
          product,
          image: { alt, localFile, fixed: { src: previewHeroImage } = {} },
        },
        i
      ) => (
        <div
          key={(localFile && localFile.id) || i}
          className={`relative mb-10 md:mb-0 ${getItemClassName(
            size,
            items.length
          )}`}
        >
          {localFile && (
            <GatsbyImage image={getImage(localFile)} alt={alt || ''} />
          )}
          {previewHeroImage && (
            <img
              src={previewHeroImage}
              alt={alt || ''}
              className="w-full h-auto"
            />
          )}
          {product && product.document && product.document.fields && (
            <Link
              className="absolute inset-0"
              to={`/${product.document.fields.pagePath}`}
            />
          )}
        </div>
      )
    )}
  </>
)

const VideoSlice = ({ children, size, primary }) => {
  return (
    <div className={`relative mb-10 md:mb-0 ${getItemClassName(size, 1)}`}>
      <video className="block" autoPlay muted loop playsInline>
        {children &&
          children.map(({ id, ext, publicURL }) => (
            <source
              key={id}
              type={`video/${ext.replace('.', '')}`}
              src={publicURL}
            />
          ))}
        {primary && primary.mp4 && primary.mp4.url && (
          <>
            <div>[VIDEO]</div>
            {/* TODO: review bandwidth usage */}
            {/* <source type="video/mp4" src={primary.mp4.url} /> */}
          </>
        )}
      </video>
    </div>
  )
}

const TextSlice = ({
  title,
  text,
  highlightTitle,
  highlightText,
  highlightFootnote,
  quote,
  quoteCite,
  alignment,
  image,
  imageSize,
}) => {
  const hasTitle = title && title !== ''
  const hasHighlight =
    highlightText && highlightText !== '' && highlightText !== '<p></p>'
  const hasQuote = quote && quote !== '' && quote !== '<p></p>'
  const hasLeftCol =
    (hasTitle || hasQuote || hasHighlight) && alignment !== ALIGNMENTS.CENTER
  const getTextColAlignment = () => {
    switch (alignment) {
      case ALIGNMENTS.CENTER:
        return ' mx-auto'
      case ALIGNMENTS.LEFT:
        return ''
      default:
        return ' ml-auto'
    }
  }
  return (
    <div className="mb-10 md:mb-0 w-full lg:w-5/6 xl:w-11/12 md:flex items-baseline md:px-3 xl:px-4">
      {hasLeftCol && (
        <div className="md:w-2/5 xl:w-1/2">
          {hasTitle && (
            <h2 className="mb-10 text-lg 2md:text-xl xl:text-2xl 2xl:text-3xl tracking-tight uppercase">
              {title}
            </h2>
          )}
          {hasHighlight && (
            <div className="mb-10 max-w-xs xl:max-w-sm 2xl:max-w-lg">
              {highlightTitle && highlightTitle !== '' && (
                <h3 className="mb-3 text-lg xl:text-2xl tracking-tight">
                  {highlightTitle}
                </h3>
              )}
              <div
                className={`leading-snug xl:text-lg ${highlightContent}`}
                dangerouslySetInnerHTML={{ __html: highlightText }}
              />
              {highlightFootnote && highlightFootnote !== '' && (
                <p className="mt-3 text-xs xl:text-sm font-sans-regular">
                  {highlightFootnote}
                </p>
              )}
            </div>
          )}
          {hasQuote && (
            <div className="relative max-w-xs xl:max-w-sm 2xl:max-w-lg pl-8 lg:pl-10 2xl:pl-16">
              <div className="absolute top-0 left-0 text-6xl lg:text-7xl 2xl:text-8xl leading-none">
                “
              </div>
              <blockquote className="mb-10">
                <div
                  className={`text-lg sm:text-xl xl:text-2xl 2xl:text-3xl leading-snug tracking-tight ${highlightContent}`}
                  dangerouslySetInnerHTML={{ __html: quote }}
                />
                {quoteCite && quoteCite !== '' && (
                  <cite className="block mt-3 not-italic text-xs xl:text-sm">
                    {quoteCite}
                  </cite>
                )}
              </blockquote>
            </div>
          )}
        </div>
      )}
      <div className={`md:w-3/5 xl:w-1/2 md:pl-6${getTextColAlignment()}`}>
        <div
          className={`w-full max-w-lg xl:max-w-xl 2xl:max-w-2xl ${textSliceContent}`}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  )
}

const Slices = ({ body, allShopifyProduct, addVariantToCart }) => {
  const renderSlice = (type, items, children, size, primary) => {
    switch (type) {
      case SLICE_TYPES.PRODUCTS:
        return (
          <ProductSlice
            items={items}
            size={size}
            allShopifyProduct={allShopifyProduct}
            addVariantToCart={addVariantToCart}
          />
        )
      case SLICE_TYPES.IMAGES:
        return <ImageSlice items={items} />
      case SLICE_TYPES.VIDEO:
        return <VideoSlice children={children} size={size} primary={primary} />
      case SLICE_TYPES.TEXT: {
        const {
          section_title: { text: title },
          text: { html: text } = {},
          quote: { html: quote } = {},
          quote_cite: quoteCite,
          alignment,
          image,
          size: imageSize,
          highlight_title: { text: highlightTitle } = {},
          highlight_text: { html: highlightText } = {},
          highlight_footnote: highlightFootnote,
        } = primary
        return (
          <TextSlice
            title={title}
            text={text}
            quote={quote}
            quoteCite={quoteCite}
            alignment={alignment}
            image={image}
            imageSize={imageSize}
            highlightTitle={highlightTitle}
            highlightText={highlightText}
            highlightFootnote={highlightFootnote}
          />
        )
      }
      default:
        return null
    }
  }

  return (
    <div>
      {body.map(
        ({
          id,
          items,
          children,
          primary,
          primary: { alignment, size },
          slice_type,
        }) => (
          <div key={id} className={getGridClassName(alignment)}>
            {renderSlice(slice_type, items, children, size, primary)}
          </div>
        )
      )}
    </div>
  )
}

Slices.propTypes = {
  body: PropTypes.array,
  allShopifyProduct: PropTypes.object,
  addVariantToCart: PropTypes.func,
}

Slices.defaultProps = {
  body: [],
  allShopifyProduct: null,
  addVariantToCart: () => {},
}

export default Slices
