/* eslint-disable import/no-anonymous-default-export */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { renderToStaticMarkup } from 'react-dom/server'
import { withPreview } from 'gatsby-source-prismic'

import globalTranslations from '../translations/global.json'
import { setPageOverlayVisibility } from '../actions/pageOverlayVisibility'

export default WrappedComponent => {
  class hocComponent extends Component {
    constructor(props) {
      super(props)
      const { initialize, pageContext } = props
      const { lang } = pageContext
      initialize({
        languages: [
          { name: 'Español', code: 'es-es' },
          { name: 'English', code: 'en-gb' },
        ],
        translation: globalTranslations,
        options: {
          defaultLanguage: lang,
          renderToStaticMarkup,
        },
      })
    }

    componentDidMount() {
      const { hideOverlay } = this.props
      hideOverlay()
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  hocComponent.propTypes = {
    initialize: PropTypes.func,
    pageContext: PropTypes.object,
    hideOverlay: PropTypes.func,
  }

  const mapDispatchToProps = dispatch => {
    return {
      hideOverlay: () => dispatch(setPageOverlayVisibility(false)),
    }
  }

  return withPreview(
    withLocalize(connect(null, mapDispatchToProps)(hocComponent))
  )
}
