import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Translate } from 'react-localize-redux'

import { title as titleClassname, image } from './LinkedProduct.module.scss'
import { getFormattedPrice } from '../../utils/getFormattedPrice'

/*
TODO: ficar titol en 2/3 linies: subcategoria + color + disseny
*/

const globalDiscountPct = parseInt(
  process.env.GATSBY_GLOBAL_DISCOUNT_PCT || '0',
  10
)

const LinkedProduct = ({
  availableForSale,
  heroImage,
  isNew,
  isPreorder,
  pagePath,
  title,
  price,
  previewHeroImage,
}) => {
  return (
    <div className="relative mb-10 md:mb-0 text-sm leading-tight">
      <div className={`relative mb-3 md:mb-4 ${image}`}>
        {isNew && (
          <div className="flex items-center justify-center absolute top-0 right-0 mt-4 mr-4 rounded-full bg-harvest-gold w-10 h-10 pointer-events-none z-10">
            <span className="uppercase text-xs leading-none">New</span>
          </div>
        )}
        {heroImage && (
          <GatsbyImage
            image={heroImage}
            alt={title}
            backgroundColor="#F0D7C3"
          />
        )}
        {previewHeroImage && (
          <img src={previewHeroImage} alt={title} className="w-full h-auto" />
        )}
      </div>
      {isPreorder && (
        <div className="my-2 text-xs lg:text-sm uppercase leading-none text-fern-green">
          <Translate id="availableForPreorder" />
        </div>
      )}
      <div className={titleClassname}>
        <Link to={`/${pagePath}`}>{title}</Link>
      </div>
      {availableForSale &&
      globalDiscountPct > 0 &&
      !(
        pagePath?.includes('tarjeta-regalo') || pagePath?.includes('gift-card')
      ) ? (
        <div className="flex">
          <div className="line-through mr-2 text-terracotta">
            {getFormattedPrice(price)}
          </div>
          <div>
            {getFormattedPrice(
              parseFloat(price) - (globalDiscountPct / 100) * parseFloat(price)
            )}
          </div>
        </div>
      ) : (
        <div>{getFormattedPrice(price)}</div>
      )}
      {!availableForSale && (
        <div className="uppercase text-xs mt-px">
          <Translate id="soldOut" />
        </div>
      )}
    </div>
  )
}

LinkedProduct.propTypes = {
  addVariantToCart: PropTypes.func,
  availableForSale: PropTypes.bool,
  heroImage: PropTypes.object,
  isNew: PropTypes.bool,
  isPreorder: PropTypes.bool,
  pagePath: PropTypes.string,
  previewHeroImage: PropTypes.string,
  price: PropTypes.string,
  title: PropTypes.string,
  variantShopifyId: PropTypes.string,
}

LinkedProduct.defaultProps = {
  addVariantToCart: () => {},
  availableForSale: true,
  heroImage: null,
  isNew: false,
  isPreorder: false,
  pagePath: '',
  previewHeroImage: undefined,
  price: '€ --',
  title: '',
  variantShopifyId: undefined,
}

export default LinkedProduct
