import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { container } from './LongformHero.module.scss'
import SectionTitle, { TITLE_TYPES } from '../SectionTitle/SectionTitle'

const LongformHero = ({ image, title, subtitle, isPreview }) => {
  return (
    <div className={container}>
      {isPreview ? (
        <img
          src={image.fluid.src}
          alt=""
          className="absolute w-full h-full object-cover"
        />
      ) : (
        <GatsbyImage
          image={getImage(image.localFile)}
          alt={title}
          loading="eager"
          className="static block select-none"
          objectFit="cover"
          imgStyle={{ width: '100%', height: '100%' }}
          style={{ width: '100%', height: '100%' }}
          backgroundColor="#F0D7C3"
        />
      )}
      <div className="absolute right-0 bottom-0 left-0 pb-6 text-center leading-none  text-white z-10">
        <div className="text-2xl xs:text-3xl sm:text-4xl md:text-5xl xl:text-6xl 3xl:text-7xl tracking-tight uppercase">
          {title}
        </div>
        {subtitle && subtitle !== '' && (
          <div className="pt-3 -mb-3">
            <SectionTitle
              text={subtitle}
              type={TITLE_TYPES.HANDWRITTEN_ALPHA_WHITE}
            />
          </div>
        )}
      </div>
    </div>
  )
}

LongformHero.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isPreview: PropTypes.bool,
}

LongformHero.defaultProps = {
  title: '',
  subtitle: '',
  isPreview: false,
}

export default LongformHero
