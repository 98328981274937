import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import onPageRender from '../hocs/onPageRender'
import Container from '../components/Container/Container'
import Slices from '../components/Slices/Slices'
import LongformHero from '../components/LongformHero/LongformHero'
import Seo from '../components/Seo/Seo'

const Longform = ({
  data: {
    prismicLongform: { _previewable, data },
  },
}) => {
  const {
    title: { text: title },
    subtitle: { text: subtitle },
    body,
    hero_image: heroImage,
  } = data
  const isPreview = !!_previewable

  return (
    <>
      <Seo title={title} />
      <LongformHero
        image={heroImage}
        title={title}
        subtitle={subtitle}
        isPreview={isPreview}
      />
      <Container>
        <Slices body={body} />
      </Container>
    </>
  )
}

Longform.propTypes = {
  data: PropTypes.object,
}

export default onPageRender(Longform)

export const query = graphql`
  query($id: String!) {
    prismicLongform(id: { eq: $id }) {
      data {
        title {
          text
        }
        subtitle {
          text
        }
        hero_image {
          localFile {
            id
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: NONE
                layout: FULL_WIDTH
              )
            }
          }
        }
        body {
          ... on PrismicLongformBodyImages {
            id
            items {
              image {
                alt
                localFile {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      quality: 85
                      layout: FULL_WIDTH
                      placeholder: NONE
                    )
                  }
                }
              }
              product {
                document {
                  ... on PrismicProduct {
                    id
                    fields {
                      pagePath
                    }
                  }
                }
              }
              size
            }
            primary {
              alignment
            }
            slice_type: slice_type
          }
          ... on PrismicLongformBodyVideo {
            id
            children {
              ... on File {
                id
                ext
                publicURL
              }
            }
            primary {
              alignment
              size
            }
            slice_type: slice_type
          }
          ... on PrismicLongformBodyText {
            id
            primary {
              section_title {
                text
              }
              text {
                html
              }
              highlight_title {
                text
              }
              highlight_text {
                html
              }
              highlight_footnote
              quote {
                html
              }
              quote_cite
              alignment
              image {
                alt
                localFile {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      quality: 85
                      layout: FULL_WIDTH
                      placeholder: NONE
                    )
                  }
                }
              }
              size
            }
            slice_type: slice_type
          }
        }
      }
    }
  }
`
