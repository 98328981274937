/**
 * @param {String} sku
 * @param {Array} shopifyEdges
 */
export default function getShopifyProductData(sku, shopifyEdges) {
  try {
    const { node } = shopifyEdges.find(({ node }) => {
      const { variants } = node
      return variants.some(({ sku: variantSku }) => variantSku === sku)
    })
    const { shopifyId: productShopifyId, variants } = node
    const {
      price,
      shopifyId: variantShopifyId,
      availableForSale,
    } = variants.find(({ sku: variantSku }) => variantSku === sku)
    return { price, productShopifyId, variantShopifyId, availableForSale }
  } catch (error) {
    console.log(sku, error)
    return {}
  }
}
